<template>
  <div class="footer">
    <div class="container flex justify-between py-16 d-none d-md-flex d-lg-flex d-xl-flex">
      <div class="flex flex-col footer-section-top">
        <div class="flex">
          <div data-aos="fade-up" class="logo-footer">
            <router-link to="/contact-us">
              <img alt="logo.png" src="../../assets/images/PNG_MAGUREINC.webp" title="Magure" />
            </router-link>
          </div>
          <div data-aos="fade-up" class="logo-text my-auto px-3 has-font-circular-bold">MAGURE</div>
        </div>
        <div
          data-aos="fade-up"
          class="description has-font-segoe my-2"
        >Always striving to be the best.</div>
        <div class="flex">
          <div
            v-for="(icon, i) of SocialIconData"
            :key="icon.title"
            data-aos="fade-up"
            :data-aos-delay="100*i"
          >
            <social-icon :iconName="icon.image" :path="icon.url" />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="text-right has-font-segoe my-1" :style="{display: getIsFooterPage()}">
          <base-button
            data-aos="fade-right"
            data-aos-delay="300"
            :href="'/contact-us'"
            id="GetInTouchoneFooter"
            class="btn-primary-dark"
            :isBorderRadiud="false"
          >Talk Now</base-button>
        </div>
      </div>
    </div>

    <div class="border-gray-dark"></div>

    <div class="container-fluid footer-section-bottom">
      <div class="container">
        <div class="row text-white py-3">
          <div class="col-sm-12 col-md-8 col-lg-8">
            <ul class="flex mb-0 justify-center md:justify-start">
              <li class="list-none">
                <router-link to="/services">SERVICES</router-link>
              </li>
              <li class="list-none">
                <router-link to="/products">PRODUCTS</router-link>
              </li>
              <li class="list-none">
                <router-link to="/about-us">ABOUT US</router-link>
              </li>
              <li class="list-none">
                <router-link to="/careers">CAREERS</router-link>
              </li>
              <li class="list-none">
                <router-link to="/contact-us">CONTACT</router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 mt-1 sm:mt-0 sm:my-auto">
            <p class="md:text-right text-light-gray copy-rights mb-0">© Copyright Magure Inc 2021</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcon from "../../icons/SocialIcon.vue";
import DataServies from "../../services/DataService";
import BaseButton from "../BaseButton.vue";

export default {
  components: {
    SocialIcon,
    BaseButton
  },

  data() {
    return {
      SocialIconData: DataServies.socialIcons
    };
  },
  methods: {
    getIsFooterPage() {
      return this.$route.path === "/contact-us" ? "none" : "block";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/Footer.scss";
</style>